import axios from "axios";
import _ from "lodash";
import { useConfig } from "../components/ConfigContext";

export const getJobs = (config, activeFilters) => {
  return axios.post(config.sinapsiRoot + `/positions`, activeFilters).then((res) => {
    return res.data.map((original) => {
      const job = { ...original };
      job.isRemote = !_.includes(job.workTypes, "On-site", "Hybrid");
      job.isFulltime = job.contractDuration === "Full time";
      return job;
    });
  });
};

export const getFilters = (config) => {
  return axios
    .get(config.sinapsiRoot + `/positions/attributes`)
    .then((res) => res.data);
};

export const getJob = (config, jobID) => {
  return axios.get(config.sinapsiRoot + `/positions/${jobID}`).then((res) => res.data);
};

export const applyJob = (config, jobID, applicationDetails) => {
  if (jobID !== undefined)
    return axios
      .post(config.sinapsiRoot + `/positions/${jobID}/apply`, applicationDetails)
      .then((res) => res.data);
  else
    return axios
      .post(config.sinapsiRoot + "/positions/apply", applicationDetails)
      .then((res) => res.data);
};

export const getNps = (config, npsId) => {
  return axios.get(config.sinapsiRoot + `/nps/${npsId}`).then((res) => res.data);
};

export const submitNps = (config, npsId, userNpsDetails) => {
  return axios
    .post(config.sinapsiRoot + `/nps/${npsId}/send`, userNpsDetails)
    .then((res) => res.data);
};

export const getGdprConsent = (config, gdprId) => {
  return axios.get(config.sinapsiRoot + `/privacy/${gdprId}`).then((res) => res.data);
};

export const submitGdprConsent = (config, gdprId) => {
  return axios
    .post(config.sinapsiRoot + `/privacy/${gdprId}/accept`)
    .then((res) => res.data);
};

export const getContractDurations = (config) => {
  return axios.get(config.sinapsiRoot + `/positions/registers/ContractDuration`)
    .then((res) => res.data);
}
export const getRoles = (config) => {
  return axios.get(config.sinapsiRoot + `/positions/registers/Role`)
    .then((res) => res.data);
}
export const getWorkTypes = (config) => {
  return axios.get(config.sinapsiRoot + `/positions/registers/WorkType`)
    .then((res) => res.data);
}
export const getSeniorities = (config) => {
  return axios.get(config.sinapsiRoot + `/positions/registers/Seniority`)
    .then((res) => res.data);
}
export const getLocations = (config) => {
  return axios.get(config.sinapsiRoot + `/positions/registers/Locations`)
    .then((res) => res.data);
}

export const unsubscribe = (config, token) => {
  return axios.get(config.sinapsiRoot +  `/subscribers/${token}/unsubscribe`)
    .then((res) => res.data);
}

export const resubscribe = (config, token) => {
  return axios.get(config.sinapsiRoot +  `/subscribers/${token}/subscribe`)
    .then((res) => res.data);
}

export const subscribe = (config, applicationDetails) => {
  return axios.post(config.sinapsiRoot + `/subscribers/subscribe`, {
    WorkTypes: applicationDetails.workType,
    ContractDurations: applicationDetails.contractDuration,
    Seniorities: applicationDetails.seniority,
    Positions: applicationDetails.role,
    Email: applicationDetails.email,
    GDPR: applicationDetails.GDPR,
    Cities: applicationDetails.location.filter(element => element.type === 'Cities').map(element => element.name),
    Countries: applicationDetails.location.filter(element => element.type === 'Countries').map(element => element.name)
  }).then((res) => res.data);
}